var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-draggable', {
    staticClass: "w-100",
    attrs: {
      "list": _vm.component.fields,
      "handle": ".handle",
      "ghost-class": "ghost"
    }
  }, _vm._l(_vm.component.fields, function (field, index) {
    return _c('v-card', {
      key: _vm.key + index,
      staticClass: "mb-4",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-title', {
      staticClass: "py-0"
    }, [_c('vx-btn', {
      staticClass: "flex-none handle",
      attrs: {
        "icon": ""
      }
    }, [_c('vx-icon', [_vm._v("mdi-drag")])], 1), _vm._v(" #" + _vm._s(index + 1) + " "), _c('v-spacer'), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === 0
      },
      on: {
        "click": function click($event) {
          return _vm.up(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-up")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": index === _vm.component.fields.length - 1
      },
      on: {
        "click": function click($event) {
          return _vm.down(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-arrow-down")])], 1), _c('vx-btn', {
      attrs: {
        "icon": "",
        "disabled": _vm.component.fields.length == 1
      },
      on: {
        "click": function click($event) {
          return _vm.removeField(index);
        }
      }
    }, [_c('vx-icon', [_vm._v("mdi-delete")])], 1)], 1), _c('v-card-text', {
      staticClass: "mt-4"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": "name",
        "label": "Name",
        "required": ""
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeName(field);
        }
      },
      model: {
        value: field.name,
        callback: function callback($$v) {
          _vm.$set(field, "name", $$v);
        },
        expression: "field.name"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "text",
        "name": "slug",
        "label": "Slug",
        "disabled": _vm.$route.params.id && !_vm.dev
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeSlug(field);
        }
      },
      model: {
        value: field.slug,
        callback: function callback($$v) {
          _vm.$set(field, "slug", $$v);
        },
        expression: "field.slug"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "textarea",
        "name": "description",
        "label": "Description",
        "counter": "280",
        "max": "280"
      },
      model: {
        value: field.description,
        callback: function callback($$v) {
          _vm.$set(field, "description", $$v);
        },
        expression: "field.description"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "select",
        "items": _vm.types,
        "name": "type",
        "label": "Type",
        "required": ""
      },
      model: {
        value: field.type,
        callback: function callback($$v) {
          _vm.$set(field, "type", $$v);
        },
        expression: "field.type"
      }
    })], 1), field.type === 'select' ? [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "combobox",
        "items": field.items,
        "name": "items",
        "label": "Values",
        "multiple": "",
        "chips": ""
      },
      model: {
        value: field.items,
        callback: function callback($$v) {
          _vm.$set(field, "items", $$v);
        },
        expression: "field.items"
      }
    })], 1)] : _vm._e(), field.type === 'collection' ? _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "items": _vm.collections,
        "item-text": "name",
        "item-value": "_id",
        "name": "schemaId",
        "dense": "",
        "outlined": "",
        "label": "Collection schema",
        "search-input": _vm.searchCollectionName
      },
      on: {
        "update:searchInput": function updateSearchInput($event) {
          _vm.searchCollectionName = $event;
        },
        "update:search-input": [function ($event) {
          _vm.searchCollectionName = $event;
        }, function ($event) {
          return _vm.searchCollectionDebounce(field);
        }]
      },
      scopedSlots: _vm._u([_vm.collections.length < _vm.totalCollection ? {
        key: "append-item",
        fn: function fn() {
          return [_c('div', {
            directives: [{
              name: "intersect",
              rawName: "v-intersect",
              value: _vm.onIntersect,
              expression: "onIntersect"
            }],
            staticClass: "pa-4 teal--text"
          }, [_vm._v("Loading more entries ...")])];
        },
        proxy: true
      } : null], null, true),
      model: {
        value: field.schemaId,
        callback: function callback($$v) {
          _vm.$set(field, "schemaId", $$v);
        },
        expression: "field.schemaId"
      }
    })], 1) : _vm._e(), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "combobox",
        "items": field.items,
        "name": "default",
        "label": "Default value"
      },
      model: {
        value: field.default,
        callback: function callback($$v) {
          _vm.$set(field, "default", $$v);
        },
        expression: "field.default"
      }
    })], 1)], 2), _c('v-row', [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "checkbox",
        "name": "required",
        "label": "Required?"
      },
      model: {
        value: field.validation.required,
        callback: function callback($$v) {
          _vm.$set(field.validation, "required", $$v);
        },
        expression: "field.validation.required"
      }
    })], 1), _vm.multipleTypes.includes(field.type) ? _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('vx-input', {
      attrs: {
        "type": "checkbox",
        "name": "multiple",
        "label": "Multiple?"
      },
      model: {
        value: field.multiple,
        callback: function callback($$v) {
          _vm.$set(field, "multiple", $$v);
        },
        expression: "field.multiple"
      }
    })], 1) : _vm._e()], 1)], 1)], 1);
  }), 1), _c('v-card', [_c('v-card-actions', [_c('vx-btn', {
    on: {
      "click": function click($event) {
        return _vm.addField();
      }
    }
  }, [_vm._v("Add field")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }