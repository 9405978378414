<template>
  <div>
    <v-draggable class="w-100" :list="component.fields" handle=".handle" ghost-class="ghost">
      <v-card v-for="(field, index) in component.fields" :key="key + index" class="mb-4" outlined>
        <v-card-title class="py-0">
          <vx-btn icon class="flex-none handle">
            <vx-icon>mdi-drag</vx-icon>
          </vx-btn>
          #{{ index + 1 }}

          <v-spacer />

          <vx-btn icon :disabled="index === 0" @click="up(index)">
            <vx-icon>mdi-arrow-up</vx-icon>
          </vx-btn>

          <vx-btn icon :disabled="index === component.fields.length - 1" @click="down(index)">
            <vx-icon>mdi-arrow-down</vx-icon>
          </vx-btn>

          <vx-btn icon :disabled="component.fields.length == 1" @click="removeField(index)">
            <vx-icon>mdi-delete</vx-icon>
          </vx-btn>
        </v-card-title>

        <v-card-text class="mt-4">
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <vx-input
                v-model="field.name"
                type="text"
                name="name"
                label="Name"
                required
                @change="onChangeName(field)"
              />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input
                v-model="field.slug"
                type="text"
                name="slug"
                label="Slug"
                :disabled="$route.params.id && !dev"
                @change="onChangeSlug(field)"
              />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input
                v-model="field.description"
                type="textarea"
                name="description"
                label="Description"
                counter="280"
                max="280"
              />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="field.type" type="select" :items="types" name="type" label="Type" required />
            </v-col>

            <template v-if="field.type === 'select'">
              <v-col cols="12" sm="6" class="py-0">
                <vx-input
                  v-model="field.items"
                  type="combobox"
                  :items="field.items"
                  name="items"
                  label="Values"
                  multiple
                  chips
                />
              </v-col>
            </template>
            <v-col v-if="field.type === 'collection'" cols="12" sm="6" class="py-0">
              <v-autocomplete
                v-model="field.schemaId"
                :items="collections"
                item-text="name"
                item-value="_id"
                name="schemaId"
                dense
                outlined
                label="Collection schema"
                :search-input.sync="searchCollectionName"
                @update:search-input="searchCollectionDebounce(field)"
              >
                <template v-if="collections.length < totalCollection" v-slot:append-item>
                  <div v-intersect="onIntersect" class="pa-4 teal--text">Loading more entries ...</div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input
                v-model="field.default"
                type="combobox"
                :items="field.items"
                name="default"
                label="Default value"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="field.validation.required" type="checkbox" name="required" label="Required?" />
            </v-col>

            <v-col v-if="multipleTypes.includes(field.type)" cols="12" sm="6" class="py-0">
              <vx-input v-model="field.multiple" type="checkbox" name="multiple" label="Multiple?" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-draggable>

    <v-card>
      <v-card-actions>
        <vx-btn @click="addField()">Add field</vx-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { FIELD_TYPES } from '@tutti/constants';
import vDraggable from 'vuedraggable';
import debounce from 'lodash/debounce';

export default {
  components: { vDraggable },

  props: {
    component: { type: Object, required: true },
    collections: { type: Array, required: true },
    totalCollection: { type: Number, default: 0 },
  },
  data() {
    return {
      key: 0,
      types: FIELD_TYPES.filter(type => type.component),
      multipleTypes: FIELD_TYPES.filter(type => type.multiple).map(type => type.value),
      searchCollectionName: '',
    };
  },
  methods: {
    addField() {
      this.component.addField();
      this.key++;
    },
    removeField(index) {
      this.component.removeField(index);
      this.key++;
    },
    up(index) {
      const [field] = this.component.fields.splice(index, 1);
      this.component.fields.splice(index - 1, 0, field);
      this.key++;
    },
    down(index) {
      const [field] = this.component.fields.splice(index, 1);
      this.component.fields.splice(index + 1, 0, field);
      this.key++;
    },
    onChangeName(field) {
      if (!field.slug) {
        field.slug = this.$slugify(field.name, '_');
        this.key++;
      }
    },
    onChangeSlug(field) {
      field.slug = this.$slugify(field.slug, '_');
    },
    searchCollectionDebounce: debounce(async function (field) {
      if (!field.schemaId && this.searchCollectionName) {
        this.$emit('loadMoreCollections', this.searchCollectionName);
      }
    }, 500),

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('loadMoreCollections');
      }
    },
  },
};
</script>
