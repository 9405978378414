var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.created ? _c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-tabs', {
    attrs: {
      "show-arrows": _vm.$vuetify.breakpoint.xs
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Basic")]), _c('v-tab', [_vm._v("Fields")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('Basic', {
    attrs: {
      "component": _vm.component
    }
  })], 1), _c('v-tab-item', [_c('Fields', {
    attrs: {
      "component": _vm.component,
      "collections": _vm.collections,
      "total-collection": _vm.totalCollection
    },
    on: {
      "loadMoreCollections": _vm.getMoreCollections
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }